"use client";

import Image from "next/image";
import React, { useEffect, useState } from "react";
import Button from "./ui/Button";
import { CloseIcon } from "./icons/huge-icons";
import { motion } from "framer-motion";
import useInstallWpa from "@/hooks/useInstallWPA";

const InstallAppPopup = () => {
	const { installPrompt: prompt, isInstallable } = useInstallWpa();
	const [show, setShow] = useState(
		typeof window != "undefined" && sessionStorage.getItem("isInstallable")
			? sessionStorage.getItem("isInstallable") === "true"
			: true,
	);

	useEffect(() => {
		if (
			(!sessionStorage.getItem("isInstallable") ||
				sessionStorage.getItem("isInstallable") === "true") &&
			isInstallable
		) {
			setShow(true);
			// sessionStorage.setItem('isInstallable', "true");
		}
		const timeout = setTimeout(() => {
			sessionStorage.setItem("isInstallable", "false");
			setShow(false);
		}, 5000);
		return () => clearTimeout(timeout);
	}, [isInstallable]);

	const InstallApp = () => {
		if (prompt) prompt.prompt();
	};
	return (
		<>
			<motion.div
				initial={{ y: -100, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				transition={{ duration: 0.3, type: "spring" }}
				className={`fixed left-0 top-[16vw] w-full ${show && isInstallable ? "block sm:hidden" : "hidden"}`}
			>
				<div className="relative mx-auto flex w-[87.2vw] items-center justify-between gap-x-[2.6vw] rounded-[5.3vw] bg-neutral-950 p-4">
					<Image
						src="/logo.webp"
						alt="Install App"
						width={54}
						height={54}
						className="w-[14.4vw] object-cover "
					/>
					<div>
						<h1 className="text-base font-semibold text-white">
							Get Me Therapy
						</h1>
						<p className="text-[2.6vw] font-normal leading-[4.2vw] text-white">
							Add this to your home screen to access your sessions
							easily
						</p>
					</div>
					<Button
						size="AUTO"
						shape="SQUIRCLE"
						className="!text-sm !font-semibold"
						onClick={InstallApp}
					>
						Install
					</Button>
					<Button
						onClick={(e) => {
							e.stopPropagation();
							setShow(false);
							sessionStorage.setItem("isInstallable", "false");
						}}
						variant="GHOST"
						size="ICON"
						shape="SQUARE"
						className="absolute right-4 top-1 h-4 w-4 border-none   bg-transparent !p-0 text-neutral-300 shadow-card-shadow duration-200 hover:bg-transparent  active:outline-none"
					>
						<CloseIcon className="h-4 w-4" />
					</Button>
				</div>
			</motion.div>
		</>
	);
};

export default InstallAppPopup;
