"use client";
import React, { createContext, useContext, useEffect, useState } from "react";

const ProgramSelectedStateContext = createContext({
	selectedSubscriptionId: "",
	setSelectedSubscriptionId: (value: string) => {},
});

export const ProgramContext = ({ children }: { children: React.ReactNode }) => {
	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
	const changeSelectedSubscriptionId = (value: string) => {
		localStorage.setItem("selectedSubscriptionId", value);
		setSelectedSubscriptionId(value);
	};
	useEffect(() => {
		setSelectedSubscriptionId(
			localStorage.getItem("selectedSubscriptionId") || "",
		);
	}, []);
	return (
		<ProgramSelectedStateContext.Provider
			value={{
				selectedSubscriptionId,
				setSelectedSubscriptionId: changeSelectedSubscriptionId,
			}}
		>
			{children}
		</ProgramSelectedStateContext.Provider>
	);
};
const useSelectedSubscription = () => {
	const { selectedSubscriptionId, setSelectedSubscriptionId } = useContext(
		ProgramSelectedStateContext,
	);
	return [selectedSubscriptionId, setSelectedSubscriptionId] as const;
};

export default useSelectedSubscription;
