"use client";
import React from "react";
import { Provider } from "react-redux";
import store from "@/store";
import { SessionProvider } from "next-auth/react";
import { ProgramContext } from "@/store/Contexts/ProgramContext";

const Providers = ({
	children,
	session,
}: {
	children: React.ReactNode;
	session: any;
}) => {
	return (
		<SessionProvider session={session}>
			<Provider store={store}>
				<ProgramContext>{children}</ProgramContext>
			</Provider>
		</SessionProvider>
	);
};

export default Providers;
